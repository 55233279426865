<template>
  <div
    style="
  min-height:100%;
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(100, 100, 100, 0.3)), url('https://images.pexels.com/photos/681368/pexels-photo-681368.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
  background-size:cover;
"
  >
    <transition
      name="slide"
      mode="out-in"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'GetStartedLayout',
  mounted() {

  },
  methods: {

  },
}
</script>

<style scoped>

</style>
